import { useContext, useEffect } from "react";
import { ReportPartsContext } from "../context/Context";
import axios from "axios";

function JsonRouter() {
    const { setJsonRouter } = useContext(ReportPartsContext);

    useEffect(() => {
        axios.get('/data/ReportRouter.json')
            .then(response => {
                const fetchedData = response.data;
                setJsonRouter(fetchedData);
            })
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            });
    }, [setJsonRouter]);

    return null;
}

export default JsonRouter;
