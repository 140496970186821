import { FaUser } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { useContext, useState } from "react";
import ReactInputMask from "react-input-mask";
import UploadFile from "./UploadFile";
import { ReportPartsContext } from "../../../context/Context";
import Checked from "./Checked";

function Header() {
    const { setProcessedData, dataPriority, setDataPriority, name, setName, jsonName } = useContext(ReportPartsContext);
    
    const handleNameChange = (event) => {
        const value = event.target.value;
        if (/^[A-Za-z\s]*$/.test(value)) {
            setName(value);
        }
    };

    const handleDataPriorityChange = (event) => {
        const value = event.target.value;
        setDataPriority(value);
    };

    // Sugerir nome completo
    const suggestCompleteName = () => {
        const nameToSuggest = jsonName.find(savedName => 
            savedName.toLowerCase().startsWith(name.toLowerCase())
        );
        if (nameToSuggest) {
            setName(nameToSuggest);
        }
    };

    return (
        <header className="headerReport">
            <div className="title">
                <span>Relatório Peças</span>
            </div>
            <div className="name">
                <div className="text">
                    <span>NOME</span>
                </div>
                <div className="input">
                    <FaUser />
                    <input
                        type="text"
                        placeholder="Digite seu nome"
                        value={name}
                        onChange={handleNameChange}
                        onBlur={suggestCompleteName} // Sugere nome ao sair do campo
                    />
                </div>
            </div>
            <div className="date">
                <div className="text">
                    <span>Data Prioridade</span>
                </div>
                <div className="input">
                    <FaCalendarDays />
                    <ReactInputMask
                        mask="99/99/9999"
                        maskChar='_'
                        placeholder="Digite a data"
                        value={dataPriority}
                        onChange={handleDataPriorityChange}
                    />
                </div>
            </div>
            <div className="select">
                <span>Incluir caf's</span>
                <Checked />
            </div>
            <div className="inffoSelect">
                <span>Rota | ID da caf | Tipo de Serviço | Data de Promessa da Entrega</span>
            </div>
            <div className="upload">
                <UploadFile setProcessedData={setProcessedData} />
            </div>
        </header>
    );
}

export default Header;
