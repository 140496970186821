import { useState, useEffect, useContext } from "react";
import { FaDeleteLeft } from "react-icons/fa6";
import { ReportPartsContext } from "../../../context/Context.js";

function User() {
    const { jsonName, setJsonName } = useContext(ReportPartsContext); // Obtém os nomes do contexto
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Carrega os nomes do JSON no estado local
        if (jsonName) {
            setUsers(jsonName.map(name => ({ name }))); // Converte para o formato esperado
        }
    }, [jsonName]);


    return (
        <div className="userConfig">
            {users.map(({ name }) => (
                <div className="users" key={name}>
                    <span>{name}</span>
                </div>
            ))}
        </div>
    );
}

export default User;
