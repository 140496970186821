function Option({ data, onSelect }) {
    return (
        <>
            {data.map(({ icon, label, classUsing }) => (
                <div 
                    className={`option ${classUsing}`} 
                    key={label} 
                    onClick={() => onSelect(classUsing)} // Adiciona o manipulador de clique aqui
                >
                    {icon}
                    <span>{label}</span>
                </div>
            ))}
        </>
    )
}

export default Option;