import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ReportPartsContext } from '../context/Context';

function JsonName() {
  const { setJsonName } = useContext(ReportPartsContext);

  useEffect(() => {
    axios.get('/data/ReportName.json')
      .then(response => {
        const fetchedNames = response.data.savedNames;
        setJsonName(fetchedNames);
      })
      .catch(error => {
        console.error('Erro ao buscar os nomes:', error);
      });
  }, [setJsonName]);

  return null;
}

export default JsonName;
