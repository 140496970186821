import React from 'react';
import domtoimage from 'dom-to-image';
import { IoPrintSharp } from "react-icons/io5";

const PrintTable = ({ setStyleTable, setImg, imgBlack, imgWhite }) => {
    const handlePrint = () => {
        setStyleTable("bgTableWhite");
        setImg(imgBlack)

        const element = document.querySelector(`.captureDiv`);
        const originalWidth = element.style.width;

        element.style.width = '1280px';

        domtoimage.toPng(element)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'Relatório Peças Branco.png';
                link.click();
            })
            .catch((error) => {
                console.error('Oops, algo deu errado!', error);
            })
            .finally(() => {
                element.style.width = originalWidth;
                setImg(imgWhite)
                setStyleTable("bgTable"); // Restaura o estilo original
            });
    };

    return (
        <button onClick={handlePrint}><IoPrintSharp /></button> // Botão para acionar a impressão
    );
};

export default PrintTable;
