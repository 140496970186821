import { useState, useEffect, useContext } from "react";
import { ReportPartsContext } from "../../../context/Context.js";

function Router(){
    const { jsonRouter } = useContext(ReportPartsContext); // Obtém os nomes do contexto
    const [router, setRouter] = useState([]);

    console.log(router);
    
    useEffect(() => {
        // Carrega os nomes do JSON no estado local
        if (jsonRouter) {
            setRouter(jsonRouter); // Converte para o formato esperado
        }
    }, [jsonRouter]);


    return (
        <div className="routerConfig">
            {router.map(({ org, result }) => (
                <div className="users" key={org}>
                    <span>{org.replace(/,/g, " | ")} = {result}</span>
                </div>
            ))}
        </div>
    );
}
export default Router