import { FaUserGear } from "react-icons/fa6";
import { MdOutlineExitToApp, MdChurch } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";

import Option from "./Option";
import { ReportPartsContext } from "../../../context/Context";
import { useContext, useEffect, useState } from "react";
import User from "./User";
import Interior from "./Interior";
import Router from "./Router";

function Config() {
    const { openConfig, setOpenConfig } = useContext(ReportPartsContext);

    const [select, setSelect] = useState("user") 

    const data = [
        { icon: <FaUserGear />, label: 'Usuários', classUsing: "user"},
        { icon: <HiTemplate />, label: 'Rotas', classUsing: "router" },
        { icon: <MdChurch />, label: 'Interior', classUsing: "int" }
    ];

    const handleOptions = () => {
        setOpenConfig(prev => !prev);
    };

    const handleSelect = (value) => {
        setSelect(value);
    };

    return (
        <div className={`config ${openConfig ? 'config-open' : 'config-closed'}`}>
            <nav>
                <Option data={data} onSelect={handleSelect} />
            </nav>
            <div className="configOpen">
                {select === "user" ? <User /> :
                 select === "router" ? <Router /> :
                 select === "int" ? <Interior /> :
                 null
                }
            </div>

            <div className='gear' onClick={handleOptions}>
                <MdOutlineExitToApp />
            </div>
        </div>
    );
}

export default Config;
