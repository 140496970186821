import "./styles.scss";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/ReportParts');
  };

  return (
    <main className="mainHome">
      <button onClick={handleNavigation}>Relatório de Peças</button>
    </main>
  )
}

export default Home