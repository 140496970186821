import ComponentsItens from "./ComponentsItens";
import { FaShippingFast } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { CgAlignBottom } from "react-icons/cg";
import { IoAlertCircle } from "react-icons/io5";
import InterpolateColor from "./InterpolateColor"; // Supondo que essa função já foi criada em outro arquivo



function ItensTable({ item }) {
    //const invalidRouters = ["Vale", "Oeste", "L01", "Leste", "Serra", "QXD", "ECT"];
    //if (invalidRouters.includes(item.router)) return null;

    const invalidRouters = ["ECT"];
    if (invalidRouters.includes(item.router)) return null;

    // Função para calcular a porcentagem com base em item.amount
    const calculatePercentage = (amount) => {
        const maxAmount = 120;
        return Math.min((amount / maxAmount) * 100, 100); // Limita a 100%
    };

    const colorStart = '#76FF03';
    const colorEnd = '#F44336';

    // Calcular a porcentagem e interpolar a cor
    const percentage = calculatePercentage(item.amount);
    const interpolatedColor = InterpolateColor(colorStart, colorEnd, percentage / 100);

    const data = [
        { icon: <FaShippingFast />, label: 'EXP', value: item.typeExp },
        { icon: <FaTruck />, label: 'STD', value: item.typeStd }
    ];

    return (
        <div className="iten">
            <div className="routerIten">
                <span>{item.router}</span>
            </div>
            <div className="priorityIten">
                <div>
                    <span>{item.date}</span>
                </div>
                <div>
                    {item.isPrioritized ? <IoAlertCircle /> : null}
                    <span>{item.priority}</span>
                </div>
            </div>
            <ComponentsItens data={data} />
            <div className="valueIten itenTOTAL">
                <CgAlignBottom style={{ color: interpolatedColor }} />
                <span style={{ color: interpolatedColor }}>{item.amount}</span>
            </div>
        </div>
    );
}

export default ItensTable;
