function ComponentsItens({data}){
    return (
        <>
            {data.map(({ icon, label, value }) => (
                <div className={`valueIten iten${label}`} key={label}>
                    {icon}
                    <span>{value}</span>
                </div>
            ))}
        </>
    )
}

export default ComponentsItens