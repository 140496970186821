import domtoimage from 'dom-to-image';

export const captureDiv = () => {
    const element = document.querySelector(`.captureDiv`);

    const originalWidth = element.style.width;

    element.style.width = '1280px';

    domtoimage.toPng(element)
        .then((dataUrl) => {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'Relatório Peças.png';
            link.click();
        })
        .catch((error) => {
            console.error('Oops, algo deu errado!', error);
        })
        .finally(() => {
            element.style.width = originalWidth;
        });
};