
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { UserProvider } from "./context/Context"
import Application from "./page/ReportParts/Application";

import { ReportPartsProvider } from "./context/Context";
import Home from "./page/Home";


function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/ReportParts" element={
            <ReportPartsProvider>
              <Application />
            </ReportPartsProvider>
          } exact />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
