import { createContext } from "react";
import React, { useState } from 'react';

const Context = createContext();
const ReportPartsContext  = createContext();

const UserProvider = ({children}) => {
    return (
        <Context.Provider value={{}}>
            {children}
        </Context.Provider>
    );
};

const ReportPartsProvider = ({children}) => {
    
    const [processedData, setProcessedData] = useState([]);
    const [processedDataResult, setProcessedDataResult] = useState([]);
    const [checked, setChecked] = useState(false);
    const [dataPriority, setDataPriority] = useState('');
    const [name, setName] = useState('');
    const [openConfig, setOpenConfig] = useState(false);

    const [jsonName, setJsonName] = useState([]);
    const [jsonRouter, setJsonRouter] = useState([]);

    return (
        <ReportPartsContext.Provider value={{name, setName, processedData, setProcessedData, checked, setChecked, dataPriority, setDataPriority, processedDataResult,  setProcessedDataResult, openConfig, setOpenConfig, jsonName, setJsonName, jsonRouter, setJsonRouter}}>
            {children}
        </ReportPartsContext.Provider>
    );
};

export { Context, UserProvider, ReportPartsContext, ReportPartsProvider };
