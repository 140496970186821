import ItensTable from './ItensTable';
import Message from './Message';
import imgWhite from "../../../assets/img/ASS_White.png";
import imgBlack from "../../../assets/img/ASS_Black.png";
import { useContext, useEffect, useRef, useState } from 'react';
import { ReportPartsContext } from '../../../context/Context';
import { captureDiv } from './CaptureDiv';
import ErrorProcessedData from './ErrorProcessedData';

import { FaDownload } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";

import PrintTable from './PrintTable';

function Main() {
    const { processedData, checked, dataPriority, name, setOpenConfig, jsonRouter } = useContext(ReportPartsContext);
    const [processedDataResult, setProcessedDataResult] = useState([]);
    const [typeItem, setTypeItem] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [styleTable, setStyleTable] = useState("bgTable");
    const [img, setImg] = useState(imgWhite);

    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}`;

    useEffect(() => {
        if (ErrorProcessedData(processedData)) return;

        const types = [...new Set(processedData.map(item => item.type))];


        setTypeItem(types);

        const allItems = processedData.reduce((acc, item) => {
            acc.push(...item.data);
            return acc;
        }, []);
        setItemList(allItems);

    }, [processedData]);

    useEffect(() => {
        if (checked) {
            setFilteredList(itemList);
        } else {
            const filtered = itemList.filter(item => item.ID === "0");
            setFilteredList(filtered);
        }
    }, [itemList, checked, dataPriority]);

    useEffect(() => {

        const newData = filteredList.map(item => {
            const rotaValue = item.Rota.match(/\[(.*?)\]/)?.[1];

            const stayValue = item.Rota.split("-")[1];

            return {
                ...item,
                Rota: rotaValue,
                Stay: stayValue
            };
        }).filter(item => item.Stay === "FOR");

        newData.forEach(item => {
            if (item.Rota.startsWith("S")) {
                item.Rota = "@ Serra";
            } else if (item.Rota.startsWith("W")) {
                item.Rota = "@ Oeste";
            } else if (item.Rota.startsWith("V")) {
                item.Rota = "@ Vale";
            } else if (item.Rota.startsWith("L") && item.Rota !== "L01") {
                item.Rota = "@ Leste";
            } else if (item.Rota.startsWith("MA")) {
                item.Rota = "MAR";
            } else if (item.Rota.startsWith("MR")) {
                item.Rota = "MRC";
            } else if (item.Rota.startsWith("QX")) {
                item.Rota = "@ QXD";
            }
        });

        newData.forEach(item => {
            jsonRouter.forEach(router => {
                // Verifica se a Rota está em org
                if (router.org.split(",").includes(item.Rota)) {
                    item.Rota = router.result; // Transforma a Rota para o result correspondente
                }
            });
        });

        // Função para tratar as rotas para ordenação
        const transformForSorting = (rota) => {
            if (/^0[1-9]\w$/.test(rota)) {
                return rota.slice(1); // Tipo 1: Remove o primeiro caractere
            } else if (/^\d\w\d$/.test(rota)) {
                return rota.slice(0, -1); // Tipo 2: Remove o último caractere
            } else {
                return rota; // Para os outros tipos, mantém a mesma
            }
        };

        // Ordenar newData
        newData.sort((a, b) => {
            const transformedA = transformForSorting(a.Rota);
            const transformedB = transformForSorting(b.Rota);

            // Ordenar primeiro pelo resultado transformado
            if (transformedA < transformedB) return -1;
            if (transformedA > transformedB) return 1;

            // Se forem iguais, manter a ordem original
            return a.Rota.localeCompare(b.Rota);
        });

        const result = newData.reduce((acc, item) => {
            const router = item.Rota;

            // Verifica se o router já existe no acumulador
            let routerData = acc.find(r => r.router === router);
            if (!routerData) {
                routerData = {
                    router,
                    amount: 0,
                    typeExp: 0,
                    typeStd: 0,
                    priority: 0,
                    date: null,
                    isPrioritized: false // Novo campo booleano
                };
                acc.push(routerData);
            }

            // Atualiza os contadores
            routerData.amount += 1; // Conta a ocorrência
            const tipo = parseInt(item.Tipo, 10);

            if (tipo > 0) {
                routerData.typeExp += 1; // Incrementa o contador de tipoExp
            } else {
                routerData.typeStd += 1; // Incrementa o contador de tipoStd
            }

            // Atualiza a data mais antiga
            if (item.Data) {
                const itemDate = new Date(item.Data.split("/").reverse().join("-"));
                if (!routerData.date || itemDate < new Date(routerData.date.split("/").reverse().join("-"))) {
                    routerData.date = item.Data; // Mantém a data mais antiga
                    routerData.priority = 1; // Reseta a contagem de prioridade para 1
                    routerData.isPrioritized = itemDate <= new Date(dataPriority.split("/").reverse().join("-")); // Verifica se é mais antiga ou igual à data de prioridade
                } else if (item.Data === routerData.date) {
                    routerData.priority += 1; // Incrementa a contagem se a data é a mesma que a mais antiga
                }
            }

            // Atualiza o campo booleano baseado na data mais antiga
            if (routerData.date) {
                routerData.isPrioritized = new Date(routerData.date.split("/").reverse().join("-")) <= new Date(dataPriority.split("/").reverse().join("-"));
            }

            return acc;
        }, []);

        setProcessedDataResult(result)

    }, [filteredList]);

    const handleOptions = () => {
        setOpenConfig(prev => !prev);
    };

    return (
        <main className='mainReport'>
            <div className='gear' onClick={handleOptions}>
                <FaGears />
            </div>
            <div className="message">
                <Message typeItem={typeItem} processedDataResult={processedDataResult} dataPriority={dataPriority} name={name} />
            </div>
            <div className="table">
                <div className='button'>
                    <button onClick={captureDiv}><FaDownload /></button>
                    <PrintTable setStyleTable={setStyleTable} setImg={setImg} imgBlack={imgBlack} imgWhite={imgWhite} />
                </div>
                    
                <div className={`${styleTable} captureDiv`}>
                    <img src={img} alt="Logo" />
                    <div className='title'>
                        <div className='suari'>
                            <span>Relatorio peças recebidas</span>
                            <span>
                                {typeItem.length === 0 ? '' :
                                    typeItem.length === 1 ? typeItem[0] :
                                        typeItem.slice(0, -1).join(' | ') + ' | ' + typeItem[typeItem.length - 1]}
                            </span>

                        </div>
                        <div className='secInffo'>
                            <span>{formattedDate}</span>
                        </div>
                    </div>
                    <div className='itensTable'>
                        <div className='headerTable'>
                            <span>ROTA</span>
                            <span>PRIORIDADE</span>
                            <span>EXP</span>
                            <span>STD</span>
                            <span>TOTAL</span>
                        </div>
                        {processedDataResult.map((item) => (
                            <ItensTable key={item.router} item={item} />
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Main;
