function ErrorProcessedData(processedData) { 

    for (let i = 0; i < processedData.length; i++) {
        if (processedData[i].data == null) {
            return true; 
        }
    }
    
    return false; 
}

export default ErrorProcessedData;