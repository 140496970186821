import "./styles.scss";

import Header from "../Header/Header.js";
import Main from "../Main/Main.js";
import Config from "../Config/Config.js";
import JsonName from "../../../json/JsonName.js";
import JsonRouter from "../../../json/JsonRouter.js";



function Home(){
    JsonName();
    JsonRouter();
    return (
        <>
            <Header />
            <Main />
            <Config />
        </>
    )
}

export default Home